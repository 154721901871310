html {
	height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #bbdce0;
  font-family: Cabin, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1, h2, h3, em {
  font-family: Raleway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#root {
	height: 100%;
}
