@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,900");

.modal__header {
  font-size: 18px;
  color: #f50057;
  font-weight: 600;
  padding: 16px 0 0 0; }

.modal__text {
  padding: 12px 0;
  font-weight: 500; }
  .modal__text p {
    line-height: 1.5em; }

.modal__wrapper {
  position: relative;
  width: 100%;
  text-align: center; }
  .modal__wrapper .modal__button {
    width: 100% }

.modal__wrapper__secondary {
  position: relative;
  text-align: center; }
  .modal__wrapper__secondary .modal__button {
    min-height: 50px;
    padding-top: 10px;
    margin: auto; }

.modal__button > * {
  width: 100%;
  margin: 4px 0; }

.modal__form > * {
  margin: 4px 0; }
