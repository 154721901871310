.Portal {
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Portal-body {
  display: flex;
  height: 100%;
  align-items: stretch;
}

.Portal li {
  padding-bottom: 10px;
}

.Portal-side-nav {
  width: 190px;
  background-color: #eceaea73;
  height: 100%;
  border-right: 1px solid #d8d5d5;
  padding: 20px;
  box-sizing: border-box;
}

.Portal-side-nav a {
  display: block;
  text-decoration: none;
  margin: 10px 0 20px 10px;
  color: #3e3e3e;
}

.Portal-content {
  height: 100%;
  flex-grow: 1;
}

.Portal-toast {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #9dd9e0;
  padding: 10px 20px;
  font-size: 1em;
  border-top: 1px solid #00b8cc;
  display: flex;
  align-items: center;
}

.Portal-toast-center {
  margin: auto;
  display: flex;
  align-items: center;
}

.Portal-toast-container {
  margin-left: 30px;
  display: flex;
}

.Portal-toast-container Button {
  margin-right: 10px;
  height: 2em;
}
