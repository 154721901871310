.Game-container {
  margin: 25px 40px 0px 0;
  display: flex;
  flex-direction: column;
}

.Game-tile {
  margin-bottom: 5px;
}

.Games-list {
  display: flex;
  flex-flow: wrap;
}

.Games-editButton {
  width: 200px;
  margin-top: 5px;
}

.Games-tutorialButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.Games-tutorialButtons .Games-editTutorialButton {
  min-width: unset;
  flex: 2;
}

.Games-tutorialButtons .Games-reviewTutorialButton {
  background-color: white;
}
