.GameEditor h3 {
    margin: 26px 0 0;
}

.GameEditor-smallprint {
    font-size: 11px;
}

.GameEditor-title-container {
	padding: 0 0 20px;
}

.GameEditor-form-container {
	display: flex;
	justify-content: flex-start;
}

.GameEditor-left-column {
	width: 240px;
}

.GameEditor-right-column {
	flex-grow: 2;
	padding: 0 20px 0 40px
}

.GameEditor-button-container {
	justify-content: space-between;
	display: flex;
	height: 30px;
	width: 100%;
	padding: 20px 0 0;
}

.GameEditor img {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  margin: 10px auto;
  display: block;
}

.GameEditor .GameEditor-img-cover-image {
	max-height: 68px;
}

.GameEditor-short-text {
	text-align: center;
	margin: 10px 0;
}

.GameEditor-long-text {
	margin: 10px 0 20px;
}

.GameEditor-section {
	display: grid;
	grid-template-columns: 50% 50%;
	padding: 10px 0 20px;
}

.GameEditor-section-column {
	display: grid;
	grid-template-columns: 40% 25% 10% 25%;
	padding: 0 0 0 40px;
}

.GameEditor-section-column-left {
	margin: auto 0;
}

.GameEditor-section-column-center {
	margin: auto;
}

