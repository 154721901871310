.explorer-container {
  width: 100%;
  height: 100%;
  background: #e9eff0;
  user-select: none;
}

.explorer-nav {
  width: auto;
  height: 40px;
  margin-bottom: 24px;
}

.explorer-nav .explorer-nav-button-up {
  margin-top: 10px;
  margin-left: -5px;
  float: left;
  background: transparent;
  border: none;
}

.explorer-nav .explorer-nav-button-folder {
  margin-top: 10px;
  margin-left: 5px;
  float: left;
  background: transparent;
  border: none;
}

.explorer-loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
}

.explorer-path {
  position: absolute;
  display: flex;
  z-index: 5;
  flex-wrap: wrap;
  float: left;
  top: 1px;
  left: 13px;
  font-size: 16px;
  border-radius: 2px;
  padding: 4px 12px 10px 8px;
}

.filter p {
  font-size: 18px;
  line-height: 12px;
}

.filter .filter-checkbox {
  width: 20px;
  margin-right: 5px;
  float: left;
}

.explorer {
  overflow: scroll;
  background: white;
  position: absolute !important;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 66%;
  height: 87%;
  border: solid thin lightgray;
  padding: 0 20px;
}

.explorer .explorer-text {
  font-size: 32px;
  font-weight: bold;
  color: darkgray;
  text-align: center;
  opacity: 0.5;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.explorer-container .status-message-container {
  position: absolute;
  background-color: #d1fbffd1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.explorer-container .status-message-container.show {
  opacity: 1;
  visibility: visible;
}

.explorer-container .status-message {
  font-weight: 100;
}

.explorer-container .button-container {
  margin: 10px 0;
}

.error-container {
  position: absolute;
  top: 100px;
  right: 20px;
  bottom: 40px;
  left: 300px;
  overflow: scroll;
}

.error-table {
  margin: 10px 0;
}

.error-table th {
  background-color: #fed7f6;
  padding: 6px 10px;
}

.error-table tr {
  background-color: #efe7ed;
}

.error-table td {
  padding: 6px 10px;
}

.explorer-item {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 90px;
  margin: 18px 15px;
}

.explorer-item .item-folder {
  background: none;
  max-width: 100%;
  max-height: 100%;
}

.explorer-item .item-container {
  height: 74px;
  padding: 8px;
  align-items: center;
  margin-bottom: 2px;
  display: flex;
}

.explorer-item .item-image {
  background-image: linear-gradient(45deg, #e8eeef 25%, transparent 25%),
  linear-gradient(-45deg, #e8eeef 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, #e8eeef 75%),
  linear-gradient(-45deg, transparent 75%, #e8eeef 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  max-width: 100%;
  max-height: 100%;
}

.explorer-item .item-text {
  white-space: normal;
  word-break: break-word;
  font-size: 15px;
  min-height: 20px;
  text-align: center;
}

.explorer-item .text-area {
  max-width: 80px;
  height: 60px;
  resize: none;
  font-weight: 500;
  font-size: 14px;
  font-family: Raleway;
  overflow: hidden;
}

.details-container {
  background: white;
  float: right;
  position: relative;
  border: solid thin lightgray;
  width: 25%;
  height: 91%;
}

.details-loader {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 80px;
}

.details-thumbnail {
  width: 100%;
  height: 160px;
}

.details-thumbnail .details-image {
  background-image: linear-gradient(45deg, #e8eeef 25%, transparent 25%), linear-gradient(-45deg, #e8eeef 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e8eeef 75%), linear-gradient(-45deg, transparent 75%, #e8eeef 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  display: block;
  margin: 20px auto;
  max-width: 90%;
  max-height: 150px;
}

.details-thumbnail .details-folder {
  display: block;
  margin: 20px auto;
  width: 50%;
  height: 100px;
  padding: 10px;
  background: url("./images/folder.png") no-repeat;
  background-size: contain;
}

.details-data {
  font-size: 15px;
  margin-top: 15px;
  width: auto;
}

.details-data ul {
  margin-left: 10px;
  padding: 0;
}

.details-data li {
  list-style-type: none;
  padding: 1px 10px 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
}

.details-data-input {
  display: flex;
  margin-left: 5px;
}

.details-data-meta {
  font-size: 13px;
  margin-left: 32px;
}

.details-data-name {
  background: white;
  border: solid 1px lightgray;
  border-radius: 0;
  padding: 5px;
  margin: 1px 4px 0px 1px;
  font: inherit;
}

.details-button-material {
  background: white;
  margin-left: 5px;
  border: none;
  outline: none;
}

.details-button {
  background-color: rgba(0, 144, 161, 1);
  min-width: 12em;
  width: auto;
  color: #fff;
  line-height: 1.8em;
  font-size: 1em;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  border-bottom: .15em solid rgba(80, 90, 80, .3);
  cursor: pointer;
  outline: none;
}

.details-button.details-button-select {
  display: block;
  bottom: 5px;
  margin: 20px auto;
}

.details-locales {
  margin-left: 42px;
}

.details-locales .details-locales-flag {
  float: left;
  margin-right: 5px;
}

.Dropdown-root {
  float: right;
  position: relative;
  width: 250px;
  margin-right: 305px;
  margin-top: 10px;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: #0090a1;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  height: 30px;
  color: white;
  cursor: default;
  outline: none;
  margin: 10px 10px 0 5px;
  padding: 3px 13px 8px 10px;
  transition: all 200ms ease;
  text-align: center;
  border-width: 0;
  border-radius: 3px;
  font-family: Raleway;
  font-weight: 600;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin: 0 10px 8px 3px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 95%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: #333333;
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 8px 10px;
  font-family: Raleway;
  font-weight: 600;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option.flag {
  width: 100%;
}

.Dropdown-option.plus {
  width: 100%;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
