.Menu-modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Menu-modal {
  width: 500px;
  background: white;
  margin: 100px auto;
  padding: 10px 40px 38px;
  border-radius: 5px;
}

.Menu-modal-title {
  text-align: center;
}

.Menu-modal-button-container {
  margin-top: 30px;
  text-align: center;
}

