.Login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: url(dized-logo-background.svg) no-repeat 50% 5% #E3FBFE;
}

.Login-central {
    text-align: center;
    width: 500px;
    height: 400px;
    background-color: white;
    margin: 60px auto;
    padding: 40px;
    border-radius: 5px;
}

.Login-info {
    padding: 18px 0 6px;
    height: 56px;
    font-size: 1.2em;
}

.Login-error {
    padding: 18px 0 6px;
    height: 56px;
    font-size: 1em;
    color: red;
    margin-top: 26px;
    box-sizing: border-box;
}

.Login-error-border {
	border: 1px solid red;
}

.Login-label {
    display: block;
    text-align: left;
    padding: 20px 0 4px;
}

.Login-textinput {
    display: block;
    width: 100%;
    background-color: #E4F3F5;
    border: none;
    border-radius: 2px;
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
}

.Login-button {
    background-color: #0090A1;
    color: white;
    border-radius: 3px;
    border: none;
    width: 100px;
    padding: 2px 0 5px;
    margin-top: 10px;
}
